module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"projects":"/Users/jerry/Code/Jerrytron/jerrytron-com/src/templates/project.js","default":"/Users/jerry/Code/Jerrytron/jerrytron-com/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-embedder"},{"resolve":"gatsby-remark-emojis","options":{"active":true,"class":"emoji-icon","escapeCharacter":"#","size":64,"styles":{"display":"inline","margin":"0","margin-top":"1px","position":"relative","top":"5px","width":"25px"}}},{"resolve":"gatsby-remark-vscode","options":{"theme":{"default":"Quiet Light","dark":"Dark+ (default dark)"},"inlineCode":{"marker":"^"},"wrapperClassName":"vscode-highlight-line","injectStyles":true,"extensions":[],"languageAliases":{},"logLevel":"warn"}}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/jerry/Code/Jerrytron/jerrytron-com","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jerrytron.com","short_name":"Jerrytron","start_url":"/","background_color":"#272789","theme_color":"#1b1b5d","display":"standalone","icon":"src/images/jerry-pixel.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/projects/*","/tags/*"],"workboxConfig":{"globPatterns":["**/*"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-41579957-2","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
